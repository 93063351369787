import { createReducer, on } from '@ngrx/store';
import { assign } from 'lodash';
import { addIcon } from './actions';
import { IIconsResources } from './model';
const initialState: Readonly<IIconsResources> = {
  icons: {},
};
export const iconsReducer = createReducer(
  initialState,
  on(addIcon, (state, { name, data }) => assign({}, state, { icons: assign({}, state.icons, { [name]: data }) })),
);
