import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';

@Injectable()
export class AuthOfficeInterceptor implements HttpInterceptor {
  constructor(private auth: AuthOfficeService) {}

  /**
   * Intercepts HTTP requests to add JWT token and handle token refresh on 401 errors.
   * @param req - The outgoing HTTP request.
   * @param next - The next interceptor in the chain.
   * @returns An observable of the HTTP event or any error.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    const isRefreshing = req.url.indexOf('auth/refresh') > -1;
    return this.cloneJwt(next, req, isRefreshing ? this.auth.refresh_token() : this.auth.token()).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          return isRefreshing
            ? this.errorRefreshing()
            : this.auth.refresh().pipe(concatMap(() => this.cloneJwt(next, req, this.auth.token())));
        } else {
          return throwError(err).pipe(map(() => ({ error: true, error_desc: err })));
        }
      }),
    );
  }

  /**
   * Handles the error when the token refresh fails.
   * @returns An observable of false.
   */
  private errorRefreshing() {
    // this.auth.goSign();
    return of(false);
  }

  /**
   * Clones the HTTP request and adds the JWT token to the headers.
   * @param next - The next interceptor in the chain.
   * @param req - The outgoing HTTP request.
   * @param token - The JWT token to be added to the headers.
   * @returns An observable of the HTTP event.
   */
  private cloneJwt(next: HttpHandler, req: HttpRequest<any>, token: string) {
    if (token) {
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
      req = req.clone({ headers });
    }
    return next.handle(req);
  }
}

// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { catchError, concatMap, map } from 'rxjs/operators';
// import { HttpHeaders } from '@angular/common/http';
// import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';

// @Injectable()
// export class AuthOfficeInterceptor implements HttpInterceptor {
//   constructor(private auth: AuthOfficeService) {}
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
//     const isRefreshing = req.url.indexOf('auth/refresh/office') > -1;
//     return (isRefreshing ? this.cloneRefresh(next, req) : this.cloneJwt(next, req)).pipe(
//       catchError((err: any) => {
//         if (err.status === 401) {
//           return isRefreshing
//             ? of(this.auth.logout())
//             : this.auth.refresh().pipe(concatMap(() => this.cloneJwt(next, req)));
//         } else {
//           return throwError(err).pipe(map(() => ({ error: true, error_desc: err })));
//         }
//       }),
//     );
//   }
//   cloneJwt(next: HttpHandler, req: HttpRequest<any>) {
//     return next.handle(
//       req.clone({
//         headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.token()}` }),
//       }),
//     );
//   }
//   cloneRefresh(next: HttpHandler, req: HttpRequest<any>) {
//     return next.handle(
//       req.clone({ headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.refresh_token()}` }) }),
//     );
//   }
// }
