<div class="wrapper">
  @for (toast of toasts(); track toast.id) {
    <div class="container" [ngClass]="{ show: toast.show, warning: toast.type === 0, error: toast.type === 1 }">
      <div class="box">
        <div class="image">
          <div class="circle">
            <icon
              name="ph:info-fill"
              size="42"
              [color]="toast.type === 0 ? 'secondary-color' : toast.type === 2 ? 'primary-color' : 'accent-color'"
            />
          </div>
        </div>
        <div class="txts">
          <p class="info">{{ toast.info | translate: toast.translateParams }}</p>
        </div>
        <div class="close" (click)="delete(toast)">
          <icon name="close" size="13" />
        </div>
      </div>
    </div>
  }
</div>
