import { Component, computed, effect, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IOfficeMenu, MENU } from '@libs/helper/office/office-menu.helper';
import { IDraw, IUser } from '@libs/interfaces';
import { first, Observable, startWith, switchMap } from 'rxjs';
import { PusherOfficeService } from '@libs/app.services/office/pusher.service';
import { DrawsService } from '@libs/app.services/office/draws/draws.service';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@libs/stand-alone-components/commons/icon/icon.component';
import { CryptoBalancePipe } from '@libs/pipes/crypto.balance.pipe';
import { DrawInfoComponent } from '@libs/stand-alone-components/bicho/commons/draws/draw-info/draw-info';
import { toSignal } from '@angular/core/rxjs-interop';
import { OfficeStoreSelectService } from '@libs/app.services/store/office-store-select.service';
import { OfficeStoreMutateService } from '@libs/app.services/store/office-store-mutate.service';
import { UserChipComponent } from '@libs/stand-alone-components/office/user-chip/user-chip';
import { UserOfficeService } from '@libs/app.services/office/users/users.service';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    CryptoBalancePipe,
    DrawInfoComponent,
    RouterLink,
    UserChipComponent,
    TranslateModule,
  ],
})
export class HomeComponent {
  user = computed(() => this.officeStoreSelectService.user());
  user$: Observable<IUser>;
  items = signal<IOfficeMenu[]>(MENU.filter(item => item.main));
  draws = toSignal(this.officeStoreSelectService.selectNextDraws());
  next = toSignal(
    this.pusher.drawEvaluated.pipe(
      startWith(null),
      switchMap(() => this.service.fetchNextDraws()),
    ),
  );
  constructor(
    private router: Router,
    private service: DrawsService,
    private pusher: PusherOfficeService,
    private officeStoreSelectService: OfficeStoreSelectService,
    private officeStoreMutateService: OfficeStoreMutateService,
    private userOfficeService: UserOfficeService,
  ) {
    effect(
      () => {
        if (!this.user()) {
          const user_id = this.officeStoreMutateService.getStoredUserId();
          if (user_id) {
            this.user$ = this.userOfficeService.fetchUser(user_id).pipe(first());
          }
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }
  isSelected(item) {
    return this.router.url.indexOf(item.link) == 0;
  }
  goDraw(draw: IDraw) {
    // this.officeStoreMutateService.addDraw(draw);
    // this.router.navigateByUrl('/draws/draw-pending');
  }
}
