import { createReducer, on } from "@ngrx/store";
import { assign } from 'lodash'
import { INotificationsState } from "./model";
import { addNotification, addNotifications, toggleNotificationShow, toggleNotificationSound } from "./actions";
import * as _ from "lodash";
const id_local_store = 'OFFICE_NOTIFICATION_FLAGS'
const getStorage = (): any => JSON.parse(localStorage.getItem(id_local_store)) || { show: true, sound: true }
const setStorageShow = (show: boolean) => {
  const sound = getStorage().sound
  localStorage.setItem(id_local_store, JSON.stringify({ show: show, sound: sound }))
  return show
}
const setStorageSound = (sound: boolean) => {
  const show = getStorage().show
  localStorage.setItem(id_local_store, JSON.stringify({ show: show, sound: sound }))
  return sound
}
const getShow = (): boolean => getStorage().show
const getSound = (): boolean => getStorage().sound

const initialState: Readonly<INotificationsState> = {
  notifications: [],
  show: getShow(),
  sound: getSound(),
};
export const notificationsOfficeReducer = createReducer(
  initialState,
  on(addNotifications, (state, { notifications }) => assign({}, state, { notifications: notifications })),
  on(addNotification, (state, { notification }) => assign({}, state, { notifications: _.take([notification, ...state.notifications], 100) })),
  on(toggleNotificationSound, (state) => assign({}, state, { sound: setStorageSound(!state.sound) })),
  on(toggleNotificationShow, (state) => assign({}, state, { show: setStorageShow(!state.show) })),
)
