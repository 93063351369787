import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigOfficeService } from '@libs/app.services/office/config-office.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '@libs/stand-alone-components/commons/modal/modal.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '@libs/stand-alone-components/commons/spinner/spinner';
import { ToastComponent } from '@libs/stand-alone-components/commons/toast/toast.component';
import { RouterLoaderComponent } from '@libs/stand-alone-components/commons/router-loader/router-loader.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ToastComponent,
    RouterLoaderComponent,
    RouterModule,
    ModalComponent,
    SpinnerComponent,
  ],
})
export class AppComponent implements OnInit {
  inti$: Observable<boolean>;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    document.documentElement.style.setProperty('--display-w', `${innerWidth}px`);
  }
  constructor(private ConfigOfficeService: ConfigOfficeService) {}
  ngOnInit(): void {
    this.inti$ = this.ConfigOfficeService.init();
    setInterval(() => this.onResize(), 1000);
  }
}
