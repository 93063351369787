import { Injectable } from '@angular/core';
import { ApiService } from '@libs/app.services/api.service';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { FileUploader } from 'ng2-file-upload';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { IToast, MODAL_TYPE } from '@libs/store/toast/model';
import { addToast } from '@libs/store/toast/actions';
import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  UPLOADER_FILE_URL_NEW_GALLERY = `https://lotodobicho.com/gallery`;
  uploader: FileUploader = new FileUploader({ url: this.UPLOADER_FILE_URL_NEW_GALLERY });
  onSuccessItem = new Subject();
  constructor(
    private api: ApiService,
    private store: Store,
    private authService: AuthOfficeService,
  ) {}
  setUpUploader(dir: string) {
    const options: any = {
      headers: [
        { name: 'Authorization', value: `Bearer ${this.authService.token()}` },
        { name: 'dir', value: dir },
      ],
    };
    this.uploader.setOptions(options);
    this.uploader.onSuccessItem = (fileItem: any, response: any) => this.onSuccessItem.next(response);
    this.uploader.onAfterAddingFile = (fileItem: any) => {
      fileItem.withCredentials = false;
      fileItem.upload();
    };
  }
  fetchGallery() {
    return this.api.get('gallery').pipe(MapResponseData);
  }
  addDir(dir: string) {
    return this.api.post('gallery/add/dir', { dir });
  }
  removeDir(dir: string) {
    return this.api.post('gallery/remove/dir', { dir });
  }
  toastSuccess() {
    const toast: IToast = {
      id: Date.now(),
      info: 'Image uploaded successfully',
      type: MODAL_TYPE.SUCCESS,
      exp: 2000,
    };
    this.store.dispatch(addToast({ toast }));
  }
  deleteImage({ dir, img }) {
    return this.api.delete(`gallery/remove/img/${dir.dir || '_'}/${img}`);
  }
  loadGallery() {
    this.store.dispatch({ type: '[Office] Load gallery' });
  }
}
