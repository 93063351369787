import { createReducer, on } from "@ngrx/store";
import { assign } from "lodash";
import { addToast, removeToast, setShowToast } from "./actions";
import { IToast } from "./model";
const initialState: ReadonlyArray<IToast> = [];
export const toastReducer = createReducer(
  initialState,
  on(addToast, (state, { toast }) => (state.find((t: IToast) => t.id == toast.id)) ? state : [...state, toast]),
  on(removeToast, (state, { id }) => state.filter((toast: IToast) => toast.id != id)),
  on(setShowToast, (state, { id, show }) => state.map((t: IToast) => t.id == id ? assign({}, t, { show: show }) : t)),
);