import { createReducer, on } from '@ngrx/store';
import { ITelegramState } from './model';
import {
  AddTelegramUsers,
  SetTelegramSelectedUser,
  AddTelegramSelectedMessages,
  AddMessage,
  SetMessageReaded,
  SetZeroPendingUserMessages,
  SetSearcherUsers,
  AddTelegramUserToUsers,
  IncrementChatUserCounter,
  ResetChatUserCounter,
  SetMiniAppStatsConnected,
} from './actions';
import { TELEGRAM_MESSAGE_STATE } from '@libs/interfaces';
import { uniqBy } from 'lodash';

const initialState: Readonly<ITelegramState> = {
  users: [],
  user: null,
  messages: [],
  searcherUsers: [],
  miniAppStatsConnected: [],
};
export const telegramReducer = createReducer(
  initialState,
  on(AddTelegramUsers, (state, { users }) => ({ ...state, users })),
  on(AddTelegramUserToUsers, (state, { user }) => ({ ...state, users: uniqBy([user, ...state.users], 'user_id') })),
  on(SetTelegramSelectedUser, (state, { user }) => ({ ...state, user })),
  on(AddTelegramSelectedMessages, (state, { messages }) => ({ ...state, messages })),
  on(AddMessage, (state, { message }) => {
    if (+state.user?.telegram_id === +message.message.chat.id) {
      const messages = uniqBy([...state.messages, message], '_id');
      return { ...state, messages };
    }
    return state;
  }),
  on(SetMessageReaded, (state, { _id }) => {
    const messages = state.messages.map(message => {
      if (message._id === _id) {
        return { ...message, state: TELEGRAM_MESSAGE_STATE.READED };
      }
      return message;
    });
    return { ...state, messages };
  }),
  on(SetZeroPendingUserMessages, (state, { user_id }) => {
    const users = state.users.map(user => {
      if (user.user_id === user_id) {
        return { ...user, num_pending_messages: 0 };
      }
      return user;
    });
    return { ...state, users };
  }),
  on(SetSearcherUsers, (state, { users }) => ({ ...state, searcherUsers: users })),
  on(IncrementChatUserCounter, (state, { user }) => {
    const users = state.users.map(_user => {
      if (_user.user_id === user.user_id) {
        return {
          ..._user,
          num_pending_messages: _user.num_pending_messages + 1,
          last_message_date: Date.now(),
        };
      }
      return _user;
    });
    return { ...state, users };
  }),
  on(ResetChatUserCounter, (state, { user }) => {
    const users = state.users.map(_user => {
      if (_user.user_id === user.user_id) {
        return { ..._user, num_pending_messages: 0 };
      }
      return _user;
    });
    return { ...state, users };
  }),
  on(SetMiniAppStatsConnected, (state, { stats }) => ({ ...state, miniAppStatsConnected: stats })),
);
