import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourcesOfficeService } from '@libs/app.services/office/resources-office.service';

@Injectable({
  providedIn: 'root',
})
export class GamesResolver implements Resolve<boolean> {
  constructor(private resourcesOfficeService: ResourcesOfficeService) {}

  resolve(): Observable<boolean> {
    return this.resourcesOfficeService.casino();
  }
}
