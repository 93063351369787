import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnDestroy, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalBoxComponent } from './modal-box/modal-box.component';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { StoreToastModalsService } from '@libs/app.services/store/store-toast-modals.service';
import { IModalState } from '@libs/store/modal/model';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalBoxComponent],
})
export class ModalComponent implements OnDestroy {
  private overlayRef: OverlayRef;
  overlay$: Observable<any>;
  private ovConfig = signal<OverlayConfig>(
    new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
      width: '300px',
    }),
  );
  state = toSignal(
    this.toastModalsService
      .selectModalState()
      .pipe(tap((state: IModalState) => (state.modal ? this.prompt() : this.close()))),
  );
  constructor(
    private overlay: Overlay,
    private toastModalsService: StoreToastModalsService,
  ) {}

  ngOnDestroy(): void {
    this.close();
  }
  prompt() {
    if (!this.overlay$) {
      this.overlayRef = this.overlay.create(this.ovConfig());
      this.overlayRef.attach(new ComponentPortal(ModalBoxComponent));
      this.overlay$ = this.overlayRef.backdropClick().pipe(tap(() => this.toastModalsService.removeModal()));
    }
  }
  close() {
    this.overlayRef?.dispose();
    this.overlay$ = null;
  }
}
