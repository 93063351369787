import { FIAT_CODE } from '@libs/interfaces';
import { createReducer, on } from '@ngrx/store';
import { assign } from 'lodash';
import {
  addOnRamperUrl,
  addSpots,
  pendingDeposits,
  pendingWithdraw,
  setAddress,
  setMetamaskAddress,
  setMetamaskAsProvider,
  setMetamaskBalance,
  setMetamaskConnected,
  setMetamaskLocked,
  setMetamaskNetwork,
  setWallet,
  setWallets,
  toggleDisplayFiat,
  updateBalance,
} from './actions';
import { IUserWallet } from './model';
const initialState: Readonly<IUserWallet> = {
  wallet: null,
  balance: 0,
  wallets: [],
  address: null,
  subscription: null,
  onRamperUrl: '',
  displayFiat: false,
  baseCurrency: { code: FIAT_CODE.BRL, name: '', enabled: true },
  spots: [],
  metamask: {
    connected: false,
    locked: true,
    address: '',
    wallet: null,
    balance: '',
    asProvider: false,
  },
  withdraw: null,
  pendingDeposits: [],
};
export const walletReducer = createReducer(
  initialState,
  on(setWallet, (state, { wallet }) =>
    assign({}, state, { address: null, subscription: null, wallet: wallet, balance: 0, onRamperUrl: '' }),
  ),
  on(addSpots, (state, { spots }) => assign({}, state, { spots: spots })),
  on(setWallets, (state, { wallets }) => assign({}, state, { wallets: wallets })),
  on(updateBalance, (state, { balance }) => assign({}, state, { balance: balance || state.balance })),
  on(toggleDisplayFiat, state => assign({}, state, { displayFiat: !state.displayFiat })),
  on(addOnRamperUrl, (state, { onRamperUrl }) => assign({}, state, { onRamperUrl: onRamperUrl })),
  on(setMetamaskConnected, (state, { connected }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { connected: connected }) }),
  ),
  on(setMetamaskLocked, (state, { locked }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { locked: locked }) }),
  ),
  on(setMetamaskAddress, (state, { address }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { address: address }) }),
  ),
  on(setMetamaskNetwork, (state, { wallet }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { wallet: wallet }) }),
  ),
  on(setMetamaskBalance, (state, { balance }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { balance: balance }) }),
  ),
  on(setMetamaskAsProvider, (state, { asProvider }) =>
    assign({}, state, { metamask: assign({}, state.metamask, { asProvider: asProvider }) }),
  ),
  on(pendingWithdraw, (state, { withdraw }) => assign({}, state, { withdraw: withdraw })),
  on(pendingDeposits, (state, { pendingDeposits }) => {
    return assign({}, state, { pendingDeposits: pendingDeposits });
  }),
  on(setAddress, (state, { address, subscription }) =>
    assign({}, state, { address: address, subscription: subscription }),
  ),
);
