import { IDraw } from '@libs/interfaces';
import { createReducer, on } from '@ngrx/store';
import { assign, orderBy, remove, take, uniqBy } from 'lodash';
import {
  addBet,
  addBetDraw,
  addDub,
  addDrawsTable,
  drawEvaluated,
  setSchedule,
  updateBetTypeToBet,
  updateBetAmount,
  updateBetNumbers,
  removeBetDub,
  updateBetmultiple,
  addLastBetting,
  addLastBettingBet,
  addDubFinished,
  updateOdd,
  addPendingBets,
  addPendingBet,
  removePendingBet,
  addDraw,
  addNextDraws,
  addEthBlocks,
  addEthBlock,
} from './actions';
import { IBetting } from './model';
import { RemoveArrayElement } from '@libs/helper/helper';

const initialState: Readonly<IBetting> = {
  bet: undefined,
  drawsTable: undefined,
  dub: undefined,
  schedule: undefined,
  betting: [],
  dubFinished: undefined,
  pending: [],
  draw: null,
  nextDraws: [],
  eth_blocks: [],
};
export const bettingReducer = createReducer(
  initialState,
  /**
   * Adds a dub to the state.
   */
  on(addDub, (state, { dub }) => assign({}, state, { dub: dub })),
  /**
   * Adds a draw to the state.
   */
  on(addDraw, (state, { draw }) => assign({}, state, { draw: draw })),
  /**
   * Adds next draws to the state.
   */
  on(addNextDraws, (state, { draws }) => assign({}, state, { nextDraws: draws })),
  /**
   * Adds a finished dub to the state.
   */
  on(addDubFinished, (state, { dubFinished }) => assign({}, state, { dubFinished: dubFinished })),
  /**
   * Adds last betting to the state.
   */
  on(addLastBetting, (state, { bets }) => assign({}, state, { betting: bets })),
  /**
   * Adds pending bets to the state.
   */
  on(addPendingBets, (state, { bets }) => assign({}, state, { pending: bets })),
  /**
   * Adds a pending bet to the state.
   */
  on(addPendingBet, (state, { bet }) => {
    const pending = [...state.pending, assign({}, bet, { draw: (bet.draw as any)._id || bet.draw })];
    let updatedState = assign({}, state, { pending });

    if (state.dub && state.dub.draw_id === (bet as any).draw._id) {
      const bets = orderBy(uniqBy([bet, ...state.dub.bets], '_id'), ['timestamp'], ['desc']);
      updatedState = assign({}, updatedState, { dub: assign({}, state.dub, { bets }) });
    }

    return updatedState;
  }),
  /**
   * Removes a pending bet from the state.
   */
  on(removePendingBet, (state, { bet }) => {
    const pending = [...state.pending].filter(ele => ele._id !== bet._id);
    let updatedState = assign({}, state, { pending });

    if (state.dub) {
      const bets = state.dub.bets.filter(dubBet => dubBet._id !== bet._id);
      updatedState = assign({}, updatedState, { dub: assign({}, state.dub, { bets }) });
    }

    return updatedState;
  }),
  /**
   * Adds a last betting bet to the state.
   */
  on(addLastBettingBet, (state, { bet }) => assign({}, state, { betting: take([bet, ...state.betting], 30) })),
  /**
   * Sets the schedule in the state.
   */
  on(setSchedule, (state, { schedule }) => assign({}, state, { schedule: schedule })),
  /**
   * Adds draws table to the state.
   */
  on(addDrawsTable, (state, { drawsTable }) => assign({}, state, { drawsTable: drawsTable })),
  /**
   * Adds a bet to the state.
   */
  on(addBet, (state, { bet }) => assign({}, state, { bet: bet })),
  /**
   * Updates the bet type and odd in the state.
   */
  on(updateBetTypeToBet, (state, { bt, odd }) =>
    state.bet ? assign({}, state, { bet: assign({}, state.bet, { betType: bt.denom, odd: odd, numbers: [] }) }) : state,
  ),
  /**
   * Updates the odd in the state.
   */
  on(updateOdd, (state, { odd }) =>
    state.bet ? assign({}, state, { bet: assign({}, state.bet, { odd: odd }) }) : state,
  ),
  /**
   * Updates the bet amount in the state.
   */
  on(updateBetAmount, (state, { amount }) =>
    state.bet ? assign({}, state, { bet: assign({}, state.bet, { amount: amount }) }) : state,
  ),
  /**
   * Updates the bet numbers in the state.
   */
  on(updateBetNumbers, (state, { numbers }) =>
    state.bet ? assign({}, state, { bet: assign({}, state.bet, { numbers: numbers }) }) : state,
  ),
  /**
   * Updates the bet multiple in the state.
   */
  on(updateBetmultiple, (state, { multiple }) =>
    state.bet ? assign({}, state, { bet: assign({}, state.bet, { multiple: multiple }) }) : state,
  ),
  /**
   * Adds a bet draw to the state.
   */
  on(addBetDraw, (state, { bet }) => {
    const draw_id = (bet.draw as IDraw)._id;
    let _state;
    if (draw_id == state.dub?.draw_id) {
      const bets = orderBy(uniqBy([bet, ...state.dub.bets], '_id'), ['timestamp'], ['desc']);
      _state = assign({}, state, { dub: assign({}, state.dub, { bets: bets }) });
    }
    const _draw: IDraw = state.drawsTable?.draws.find(draw => draw._id == draw_id);
    if (_draw) {
      const draws_before = [...state.drawsTable.draws];
      remove(draws_before, (d: IDraw) => d._id == draw_id);
      const _draws = [...draws_before, assign({}, _draw, { num_bets: _draw.num_bets + 1 })];
      const drawsTable = assign({}, state.drawsTable, { draws: orderBy(_draws, ['draw_timestamp'], ['asc']) });
      _state = assign({}, _state || state, { drawsTable: drawsTable });
    }
    return _state || state;
  }),
  /**
   * Evaluates a draw and updates the state.
   */
  on(drawEvaluated, (state, { draw }) => {
    const draw_id = draw._id;
    let _state;
    if (draw_id == state.dub?.draw_id) {
      _state = assign({}, state, {
        dub: assign({}, state.dub, { evaluated: true, draw: assign({}, state.dub.draw, { result: draw.result }) }),
      });
    }
    const _draw: IDraw = state.drawsTable?.draws.find(_draw => _draw._id == draw_id);
    if (_draw) {
      const draws_before = [...state.drawsTable.draws];
      remove(draws_before, (d: IDraw) => d._id == draw_id);
      const _draws = [...draws_before, assign({}, _draw, { result: draw.result })];
      _state = assign({}, _state || state, {
        drawsTable: assign({}, state.drawsTable, { draws: orderBy(_draws, ['draw_timestamp'], ['asc']) }),
      });
    }
    const next = [...state.nextDraws];
    remove(next, (d: IDraw) => d._id == draw_id);
    return assign({}, _state || state, { nextDraws: [...next] });
  }),
  /**
   * Removes a bet dub from the state.
   */
  on(removeBetDub, (state, { bet }) =>
    assign({}, state, { dub: assign({}, state.dub, { bets: RemoveArrayElement(state.dub.bets, bet) }) }),
  ),
  /**
   * Adds eth blocks to the state.
   */
  on(addEthBlocks, (state, { blocks }) => assign({}, state, { eth_blocks: blocks })),
  /**
   * Adds an eth block to the state.
   */
  on(addEthBlock, (state, { block }) => assign({}, state, { eth_blocks: take([block, ...state.eth_blocks], 100) })),
);
