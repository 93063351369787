import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { SubmitSpinnerComponent } from '../../submit-spinner/submit-spinner';
import { StoreToastModalsService } from '@libs/app.services/store/store-toast-modals.service';
import { IModalState } from '@libs/store/modal/model';
import { ButtonLeetbitComponent } from '../../button-ldb/button-ldb';
@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrls: ['./modal-box.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SubmitSpinnerComponent, ButtonLeetbitComponent],
})
export class ModalBoxComponent {
  sub$: Observable<any>;
  state = toSignal<IModalState>(this.toastModalsService.selectModalState());
  loading = signal(false);
  constructor(private toastModalsService: StoreToastModalsService) {}

  onAccept() {
    this.loading.set(true);
    this.sub$ = this.state().modal.onAccept.pipe(tap(() => this.onCancel()));
  }
  onCancel() {
    this.loading.set(false);
    this.toastModalsService.removeModal();
  }
}
