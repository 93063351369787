import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';

import { Store } from '@ngrx/store';
import { DRAW_SCHEDULE, WALLET_CODE } from '@libs/interfaces';
import { ApiService } from '@libs/app.services/api.service';

@Injectable({ providedIn: 'root' })
export class BetsService {
  constructor(
    public api: ApiService,
    public store: Store,
  ) {}
  betsNumPendingWallet(wallet: WALLET_CODE): Observable<number> {
    return this.api.get(`bets/num/pending/wallet/${wallet}`).pipe(MapResponseData);
  }
  private betsNumPendingSchedule(schedule: DRAW_SCHEDULE): Observable<number> {
    return this.api.get(`bets/num/pending/schedule/${schedule}`).pipe(MapResponseData);
  }
}
