import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { IGame } from '@libs/interfaces';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { GameSearcherService } from '@libs/app.services/casino/casino-searcher.service';
@Injectable()
export class SearcherEffects {
  searchGames;
  findEndlessGames;
  constructor(
    private actions: Actions,
    private service: GameSearcherService,
  ) {
    this.searchGames = createEffect(() =>
      this.actions.pipe(
        ofType(
          '[Searcher Session] Set Filters word',
          '[Searcher Session] Set Filters provider',
          '[Searcher Session] Set Filters category',
        ),
        exhaustMap(() =>
          this.service.search().pipe(
            map((games: IGame[]) => ({ type: '[Searcher Session] Set games', games: games })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
    this.findEndlessGames = createEffect(() =>
      this.actions.pipe(
        ofType('[Game Session]  Games endless loading'),
        exhaustMap(() =>
          this.service.fetchEndlessGames().pipe(
            map((games: IGame[]) => ({ type: '[Game Session] Add games endless', games: games })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  }
}
