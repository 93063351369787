import { Injectable } from '@angular/core';
import { ICurrencyResult, IDraw, IDrawCurrenciesInfo, IDrawUserBets, IWallet } from '@libs/interfaces';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { orderBy } from 'lodash';
import { ApiService } from '@libs/app.services/api.service';
import { addNextDraws } from '@libs/store/betting/actions';

@Injectable({ providedIn: 'root' })
export class DrawsService {
  constructor(
    public api: ApiService,
    private store: Store,
    private router: Router,
  ) {}
  fetchUpcomingDrawInfo(draw: IDraw): Observable<IDrawCurrenciesInfo> {
    return this.api.get(`draw/pending/info/${draw._id}`).pipe(
      MapResponseData,
      filter(data => {
        if (!data) {
          this.router.navigateByUrl(`/draws/draw-finished`);
          return false;
        } else return true;
      }),
      switchMap((drawCurrencies: IDrawCurrenciesInfo) => this.addWallet(drawCurrencies)),
    );
  }
  addWallet(drawCurrencies: IDrawCurrenciesInfo): Observable<IDrawCurrenciesInfo> {
    return this.store.select(selectWallets).pipe(
      tap((wallets: IWallet[]) =>
        drawCurrencies.currencies.forEach(
          (stats: ICurrencyResult) =>
            (stats.wallet = wallets.find((c: IWallet) => Number(stats.currency) == c.currency)),
        ),
      ),
      map(() => drawCurrencies),
    );
  }
  fetchDrawWinningDubs(draw: IDraw): Observable<IDrawUserBets[]> {
    return this.api.get(`draw/winning-dubs/${draw._id}`).pipe(
      MapResponseData,
      map((dubs: IDrawUserBets[]) => orderBy(dubs, ['amount'], ['desc'])),
    );
  }
  fetchNextDraws(): Observable<IDraw[]> {
    return this.api.get(`draws/next`).pipe(
      MapResponseData,
      tap((draws: IDraw[]) => this.store.dispatch(addNextDraws({ draws }))),
    );
  }
}
function selectWallets(state: object): unknown {
  throw new Error('Function not implemented.');
}
