import { notificationsOfficeReducer } from '@libs/store/notifications-office/reducers';
import { authReducer } from './auth/reducers';
import { bettingReducer } from './betting/reducers';
import { ISettings } from './settings/model';
import { settingsReducer } from './settings/reducers';
import { IToast } from './toast/model';
import { sessionGameReducer } from './game/reducers';
import { searchReducer } from './searcher/reducers';
import { walletReducer } from './wallet/reducers';
import { modalReducer } from './modal/reducers';
import { toastReducer } from './toast/reducers';
import { iconsReducer } from './icons/reducers';
import { userReducer } from './user/reducers';
import { officeReducer } from './office/reducers';
import { telegramReducer } from './telegram/reducers';

export interface IAppState {
  toasts: IToast[];
  settings: ISettings;
}
export const reducers = {
  settings: settingsReducer,
  auth: authReducer,
  betting: bettingReducer,
  user: userReducer,
  notif: notificationsOfficeReducer,
  games: sessionGameReducer,
  searcher: searchReducer,
  office: officeReducer,
  wallet: walletReducer,
  modal: modalReducer,
  toasts: toastReducer,
  icons: iconsReducer,
  telegram: telegramReducer,
};
