import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { ITelegramChatUser, ITelegramMessage } from '@libs/interfaces';
import { map, exhaustMap, catchError, tap, switchMap } from 'rxjs/operators';
import { TelegramOfficeService } from '@libs/app.services/office/telegram/telegram-office.service';
import { OfficeStoreTelegramService } from '@libs/app.services/store/office-store-telegram.service';
@Injectable()
export class TelegramEffects {
  users;
  messages;
  constructor(
    private actions: Actions,
    private service: TelegramOfficeService,
    private officeStoreTelegramService: OfficeStoreTelegramService,
  ) {
    // Effect to fetch Telegram users
    this.users = createEffect(() =>
      this.actions.pipe(
        ofType('[Telegram] fetch users'),
        exhaustMap(() =>
          this.service.fetchUsers().pipe(
            map((users: ITelegramChatUser[]) => ({ type: '[Telegram] Add chat users', users })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  }
}
