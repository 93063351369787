<div class="wrapper">
  <div class="centered">
    <div class="menu">
      <div class="items">
        @for (item of items(); track item.title) {
          <div class="item" [routerLink]="item.route">
            <icon [name]="item.icon" />
            <p class="title">{{ item.title }}</p>
            <icon name="arrow-right" />
          </div>
        }
      </div>
    </div>
    @if (user()) {
      <div class="last-user" routerLink="/user/global">
        <app-user-chip [user]="user()" />
        <div class="last-user-actions">
          <p class="title">{{ 'Last user seen' | translate }}</p>
          <p class="name">{{ user().data.name + ' ' + user().data.surname }}</p>
          <p class="email">{{ user().data.email }}</p>
        </div>
        <icon name="arrow-right" size="64" />
      </div>
    } @else {
      <div></div>
    }
    <div class="next-draws">
      @for (draw of draws(); track draw._id) {
        <div class="draw" (click)="goDraw(draw)">
          <app-draw-info [draw]="draw" [remaining]="true" />
        </div>
      }
    </div>
  </div>
</div>
@if (user$ | async) {}
