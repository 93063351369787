import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideServiceWorker } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranstalerLoader } from '@libs/functionals/translater.functionals';
import { appRoutes } from './app.routes';
import { reducers } from '@libs/store/office.initial.state';
import { OfficeEffects } from '@libs/store/office/effects';
import { SearcherEffects } from '@libs/store/searcher/effects';
import { AuthOfficeInterceptor } from '@libs/interceptors/office.auth.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BetsService } from '@libs/app.services/office/draws/user.bets.service';
import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';
import { EmployeesService } from '@libs/app.services/office/employees.service';
import { WalletService } from '@libs/app.services/wallet/wallet.service';
import { WithdrawsService } from '@libs/app.services/office/wallets/withdraws.service';
import { GalleryService } from '@libs/store/office/gallery.service';
import { DepositsService } from '@libs/app.services/office/wallets/deposits.service';
import { PusherOfficeService } from '@libs/app.services/office/pusher.service';
import { ApiService } from '@libs/app.services/api.service';
import { IconsService } from '@libs/app.services/icons.service';
import { ConfigOfficeService } from '@libs/app.services/office/config-office.service';
import { ResourcesOfficeService } from '@libs/app.services/office/resources-office.service';
import { UserOfficeService } from '@libs/app.services/office/users/users.service';
import { ScheduleService } from '@libs/app.services/bicho/schedule-selector.service';
import { LangService } from '@libs/app.services/lang.service';
import { GameSearcherService } from '@libs/app.services/casino/casino-searcher.service';
import { GamesOfficeService } from '@libs/app.services/office/games/games-office.service';
import { AwakeService } from '@libs/app.services/awake.service';
import { TableDrawsService } from '@libs/app.services/bicho/table.draws.service';
import { TelegramEffects } from '@libs/store/telegram/effects';
declare let process: any;
const StoreDevModules =
  process.env.NODE_ENV === 'development'
    ? [
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: true, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          connectInZone: true,
        }),
      ]
    : [];
export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),

    importProvidersFrom(TranslateModule.forRoot(TranstalerLoader)),
    importProvidersFrom(EffectsModule.forRoot(SearcherEffects, OfficeEffects, TelegramEffects)),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthOfficeInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    provideRouter(appRoutes, withViewTransitions()),
    provideStore(reducers),
    importProvidersFrom(StoreDevModules),

    provideEnvironmentNgxMask(),

    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PusherOfficeService,
    LangService,
    ApiService,
    AuthOfficeService,
    BetsService,
    EmployeesService,
    UserOfficeService,
    ConfigOfficeService,
    WithdrawsService,
    GalleryService,
    DepositsService,
    GamesOfficeService,
    IconsService,
    WalletService,
    ScheduleService,
    GameSearcherService,
    ResourcesOfficeService,
    AwakeService,
    TableDrawsService,
  ],
};
