import { CURRENCY } from '@libs/interfaces';
import { createReducer, on } from '@ngrx/store';
import { assign, uniq } from 'lodash';
import {
  addAvatarUrl,
  addGameBookmark,
  removeGameBookmark,
  setAccessToken,
  setAvatarImage,
  setCurrency,
  setEmployee,
  setUser,
  setUserBetTypeOdd,
  setUserEmail,
  setUsername,
  updateEmployeeSchedule,
  updatePixKey,
  updateUserCurrency,
  updateUserImageId,
  updateUserLang,
  updateUserSchedule,
  updateUserWalletType,
} from './actions';
import { IAuth } from './model';
import { RemoveArrayElement } from '@libs/helper/helper';

const initialState: Readonly<IAuth> = {
  user: undefined,
  employee: undefined,
  avatarUrl: 'assets/identifiers/mascot-head.png',
  access_token: undefined,
};
export const authReducer = createReducer(
  initialState,
  on(setUser, (state, { user }) => assign({}, state, { user: user })),
  on(setEmployee, (state, { employee }) => assign({}, state, { employee: employee })),
  on(setAccessToken, (state, { access_token }) => assign({}, state, { access_token: access_token })),
  on(addAvatarUrl, (state, { url }) => assign({}, state, { avatarUrl: url })),
  on(updateUserImageId, (state, { image_id }) => assign({}, state, { image_id: image_id })),
  on(updateUserLang, (state, { lang }) =>
    assign({}, state, { user: assign({}, state.user, { settings: assign({}, state.user.settings, { lang: lang }) }) }),
  ),
  on(setUsername, (state, { username }) =>
    assign({}, state, { user: assign({}, state.user, { data: assign({}, state.user.data, { userName: username }) }) }),
  ),
  on(setUserEmail, (state, { email }) =>
    assign({}, state, { user: assign({}, state.user, { data: assign({}, state.user.data, { email: email }) }) }),
  ),
  on(updateUserSchedule, (state, { schedule }) =>
    assign({}, state, {
      user: assign({}, state.user, { settings: assign({}, state.user.settings, { draw_schedule: schedule }) }),
    }),
  ),
  on(updateEmployeeSchedule, (state, { schedule }) =>
    assign({}, state, {
      employee: assign({}, state.employee, {
        settings: assign({}, state.employee.settings, { draw_schedule: schedule }),
      }),
    }),
  ),
  on(updateUserCurrency, (state, { code }) =>
    assign({}, state, {
      user: assign({}, state.user, {
        settings: assign({}, state.user.settings, { wallet: code, currency: CURRENCY[code] }),
      }),
    }),
  ),
  on(updateUserWalletType, (state, { wallet_type }) =>
    assign({}, state, {
      user: assign({}, state.user, { settings: assign({}, state.user.settings, { wallet_type: wallet_type }) }),
    }),
  ),
  on(updatePixKey, (state, { pixKey }) =>
    assign({}, state, {
      user: assign({}, state.user, { settings: assign({}, state.user.settings, { pixKey: pixKey }) }),
    }),
  ),
  on(setCurrency, (state, { currency }) => assign({}, state, { currency: currency })),

  on(setUserBetTypeOdd, (state, { bt, odd }) =>
    assign({}, state, {
      user: assign({}, state.user, {
        settings: assign({}, state.user.settings, { favouriteBetType: { denom: bt, odd: odd } }),
      }),
    }),
  ),
  on(addGameBookmark, (state, { id }) =>
    assign({}, state, {
      user: assign({}, state.user, {
        settings: assign({}, state.user.settings, { bookmarks: uniq([id, ...(state.user.settings.bookmarks || [])]) }),
      }),
    }),
  ),
  on(removeGameBookmark, (state, { id }) =>
    assign({}, state, {
      user: assign({}, state.user, {
        settings: assign({}, state.user.settings, {
          bookmarks: RemoveArrayElement(state.user.settings.bookmarks || [], id),
        }),
      }),
    }),
  ),
  on(setAvatarImage, state =>
    assign({}, state, {
      user: assign({}, state.user, { image_id: state.user._id }),
    }),
  ),
);
