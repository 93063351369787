import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { isUndefined } from 'lodash';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IconComponent } from '../icon/icon.component';
import { IToast } from '@libs/store/toast/model';
import { StoreToastModalsService } from '@libs/app.services/store/store-toast-modals.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [IconComponent, TranslateModule, CommonModule],
})
export class ToastComponent {
  toasts = toSignal(this.service.selectToasts().pipe(tap((toasts: IToast[]) => this.onUpdate(toasts))));

  constructor(private service: StoreToastModalsService) {}

  onUpdate(toasts: IToast[]) {
    const pending = toasts.filter((t: IToast) => isUndefined(t.show));
    pending.forEach((t: IToast) => this.add(t));
  }
  add(toast: IToast): void {
    const id = toast.id;
    const show = true;
    setTimeout(() => this.service.setShowToast(id, show), 100);
    setTimeout(() => this.delete(toast), toast.exp || 5000);
  }
  delete(toast: IToast) {
    const id = toast.id;
    const show = false;
    this.service.setShowToast(id, show);
    setTimeout(() => this.service.removeToast(id), 1000);
  }
}
