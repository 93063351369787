@if (inti$ | async) {
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <div class="toast">
    <app-toast />
  </div>
  <div class="modal">
    <app-modal />
  </div>
} @else {
  <div class="wrapper spinner">
    <app-spinner />
    <p>Loading resources</p>
  </div>
}
