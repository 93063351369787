import { Injectable } from '@angular/core';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { assign } from 'lodash';
import { Observable, combineLatest, map } from 'rxjs';
import { map_lodash } from '@libs/functionals/functionals';
import { ApiService } from '../../api.service';
import { WalletService } from '@libs/app.services/wallet/wallet.service';
@Injectable({
  providedIn: 'root',
})
export class StatsUsersService {
  constructor(
    private api: ApiService,
    private walletService: WalletService,
  ) {}
  /**
   * Retrieves referral sign-up statistics for multiple timestamps.
   * @param {number[]} timestamps - Array of timestamps to get statistics for.
   * @returns {Observable<any[]>} - An observable containing the referral sign-up statistics.
   */
  referralsSignStatsOfTimestamps(timestamps?: number[]) {
    return combineLatest(timestamps.map(timestamp => this.referralsSignStatsOfTimestamp(timestamp)));
  }

  /**
   * Retrieves referral sign-up statistics for a single timestamp.
   * @param {number} timestamp - The timestamp to get statistics for.
   * @returns {Observable<any>} - An observable containing the referral sign-up statistics.
   */
  referralsSignStatsOfTimestamp(timestamp) {
    return this.api.get(`stats/referral/sign/${timestamp}`).pipe(MapResponseData);
  }

  /**
   * Retrieves office users statistics.
   * @returns {Observable<any>} - An observable containing the office users statistics.
   */
  officeUsersStats() {
    return this.api.get('office/users/stats').pipe(MapResponseData);
  }

  /**
   * Retrieves the number of users.
   * @returns {Observable<number>} - An observable containing the number of users.
   */
  getNumUsers(): Observable<number> {
    return this.api.get('stats/num/users').pipe(MapResponseData);
  }

  /**
   * Retrieves the number of users registered today.
   * @returns {Observable<number>} - An observable containing the number of users registered today.
   */
  getTodayRegistered(): Observable<number> {
    return this.api.get('stats/num/today/registered').pipe(MapResponseData);
  }

  /**
   * Retrieves today's deposits.
   * @returns {Observable<any[]>} - An observable containing today's deposits.
   */
  getTodayDeposits(): Observable<any[]> {
    return this.api.get('stats/today/deposits').pipe(
      MapResponseData,
      map(data => this.addWallet(data)),
    );
  }

  /**
   * Adds wallet information to the data.
   * @param {any[]} data - The data to add wallet information to.
   * @returns {any[]} - The data with wallet information added.
   */
  private addWallet(data) {
    return data.map(ele => assign(ele, { wallet: this.walletService.wallets().find(w => w.currency == ele.currency) }));
  }

  /**
   * Retrieves the number of users with deposits.
   * @returns {Observable<number>} - An observable containing the number of users with deposits.
   */
  getUsersWithDeposits(): Observable<number> {
    return this.api.get('stats/users/deposits').pipe(MapResponseData);
  }

  /**
   * Retrieves the number of users with deposits today.
   * @returns {Observable<number>} - An observable containing the number of users with deposits today.
   */
  getUsersWithDepositsToday(): Observable<number> {
    return this.api.get('stats/users/deposits/today').pipe(MapResponseData);
  }

  /**
   * Retrieves the balance of users.
   * @returns {Observable<any>} - An observable containing the balance of users.
   */
  getUsersBalance() {
    return this.api.get('stats/users/balance').pipe(MapResponseData);
  }

  /**
   * Retrieves the number of connected users.
   * @returns {Observable<number>} - An observable containing the number of connected users.
   */
  getConnected(): Observable<number> {
    return this.api.get(`pusher/connected/lotodobicho`).pipe(
      MapResponseData,
      map((channels: any) => map_lodash(channels.channels, (ele, key) => key)),
      map(eles => eles.length - 4),
    );
  }
}
