import { createReducer, on } from '@ngrx/store';
import { assign, orderBy, remove, cloneDeep } from 'lodash';

import { IOffice } from './model';
import {
  addConnectedUsers,
  addGallery,
  addGamesCategories,
  addImageGallery,
  addNewDeposit,
  addNewUser,
  addNewWithdraw,
  addOfficeUserStats,
  addPendingUserDocs,
  addPendingWithdraw,
  addPendingWithdraws,
  addReferralStats,
  addUserNote,
  addUserNotes,
  removeImageGallery,
  removePendingWithdraw,
  resetSelectedUser,
  toggleDirGallery,
  updatePendingWithdraw,
} from './actions';
import { IWithdraw } from '@libs/interfaces';

const initialState: Readonly<IOffice> = {
  withdraws: [],
  usersPendingDocs: [],
  gallery: [],
  stats: null,
  selectedUser: { notes: [] },
  categoriesGames: [],
  referrals: [],
};
export const officeReducer = createReducer(
  initialState,
  on(addPendingWithdraws, (state, { withdraws }) =>
    assign({}, state, { withdraws: orderBy(withdraws, ['amount'], ['desc']) }),
  ),
  on(removePendingWithdraw, (state, { withdraw }) => {
    const withdraws = [...state.withdraws];
    remove(withdraws, (wd: IWithdraw) => wd._id == withdraw._id);
    return assign({}, state, { withdraws: withdraws });
  }),
  on(addPendingWithdraw, (state, { withdraw }) => {
    const withdraws = [...state.withdraws];
    remove(withdraws, (wd: IWithdraw) => wd._id == withdraw._id);
    return assign({}, state, { withdraws: orderBy([...withdraws, withdraw], ['amount'], ['desc']) });
  }),
  on(updatePendingWithdraw, (state, { withdraw }) => {
    const withdraws = [...state.withdraws];
    remove(withdraws, (wd: IWithdraw) => wd._id == withdraw._id);
    return assign({}, state, { withdraws: orderBy([...withdraws, withdraw], ['timestamp'], ['asc']) });
  }),
  on(addPendingUserDocs, (state, { users }) => assign({}, state, { usersPendingDocs: users })),
  on(addGallery, (state, { gallery }) => assign({}, state, { gallery: gallery })),
  on(addImageGallery, (state, { dir, image }) => {
    const index = state.gallery.findIndex(ele => ele.dir == dir);
    const _gallery = cloneDeep(state.gallery);
    _gallery[index].files.push(image);
    return assign({}, state, { gallery: _gallery });
  }),
  on(removeImageGallery, (state, { dir, image }) => {
    const index = state.gallery.findIndex(ele => ele.dir == dir);
    const _gallery = cloneDeep(state.gallery);
    remove(_gallery[index].files, ele => ele == image);
    return assign({}, state, { gallery: _gallery });
  }),
  on(toggleDirGallery, (state, { dir }) => {
    const index = state.gallery.findIndex(ele => ele.dir == dir.dir);
    const _gallery = cloneDeep(state.gallery);
    _gallery[index].show = !_gallery[index].show;
    return assign({}, state, { gallery: _gallery });
  }),
  on(addConnectedUsers, (state, { connected }) => assign({}, state, { stats: assign({}, state.stats, { connected }) })),
  on(addNewUser, state =>
    assign({}, state, {
      stats: assign({}, state.stats, {
        numUsers: (state.stats?.numUsers || 0) + 1,
        todayRegistered: (state.stats?.todayRegistered || 0) + 1,
      }),
    }),
  ),
  on(addNewDeposit, (state, { deposit }) =>
    assign({}, state, {
      stats: assign({}, state.stats, {
        deposited_today: state.stats?.deposited_today + deposit.amount,
      }),
    }),
  ),
  on(addNewWithdraw, (state, { withdraw }) =>
    assign({}, state, {
      stats: assign({}, state.stats, {
        withdrawals_today: state.stats.withdrawals_today + withdraw.amount,
      }),
    }),
  ),
  on(resetSelectedUser, state =>
    assign({}, state, {
      selectedUser: assign({}, state.selectedUser, { notes: [] }),
    }),
  ),
  on(addUserNotes, (state, { notes }) =>
    assign({}, state, {
      selectedUser: assign({}, state.selectedUser, { notes: notes }),
    }),
  ),
  on(addUserNote, (state, { note }) =>
    assign({}, state, {
      selectedUser: assign({}, state.selectedUser, { notes: [note, ...state.selectedUser.notes] }),
    }),
  ),
  on(addOfficeUserStats, (state, { stats }) => assign({}, state, { stats: stats })),
  on(addGamesCategories, (state, { categoriesGames }) => assign({}, state, { categoriesGames: categoriesGames })),
  on(addReferralStats, (state, { referrals }) => assign({}, state, { referrals: referrals })),
);
