import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { range } from 'lodash';
import { GetTimestamp00Hours } from '@libs/functionals/dates.functionals';
import { IUser, IUserNote, IWithdraw } from '@libs/interfaces';
import { WithdrawsService } from '@libs/app.services/office/wallets/withdraws.service';
import { UserOfficeService } from '@libs/app.services/office/users/users.service';
import { GalleryService } from './gallery.service';
import { StatsUsersService } from '@libs/app.services/office/stats/stats.users.service';
import { OfficeStoreSelectService } from '@libs/app.services/store/office-store-select.service';
import { GamesOfficeService } from '@libs/app.services/office/games/games-office.service';
import { UsersDocsService } from '@libs/app.services/office/users/users-docs.service';
@Injectable()
export class OfficeEffects {
  private pendingWithdraws;
  private pendingUserDocs;
  private gallery;
  private usersStats;
  private notes;
  private gamesCategories;
  private referralStats;
  constructor(
    private galleryService: GalleryService,
    private actions: Actions,
    private service: WithdrawsService,
    private usersService: UserOfficeService,
    private userStats: StatsUsersService,
    private games: GamesOfficeService,
    private officeStoreService: OfficeStoreSelectService,
    private usersDocsService: UsersDocsService,
  ) {
    this.pendingWithdraws = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load pending withdraws'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.service.fetchPendingWithdraws().pipe(
            map((withdraws: IWithdraw[]) => ({ type: '[Office] Add pending withdraws', withdraws: withdraws })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
    this.pendingUserDocs = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load pending users pending docs'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.usersDocsService.fetchUserDocsPendingRecentlyUploaded().pipe(
            map((users: IUser[]) => ({ type: '[Office] Add pending users docs', users: users })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
    this.referralStats = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load new users referral stats'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.userStats
            .referralsSignStatsOfTimestamps(
              range(0, 4).map(days => GetTimestamp00Hours(Date.now()) - days * 24 * 60 * 60 * 1000),
            )
            .pipe(
              map((referrals: any[]) => ({ type: '[Office] Add referral stats', referrals: referrals })),
              tap(() => this.officeStoreService.setLoading(false)),
              catchError(() => {
                this.officeStoreService.setLoading(false);
                return EMPTY;
              }),
            ),
        ),
      ),
    );
    this.notes = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load selected user notes'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.usersService.fetchUserNotes().pipe(
            map((notes: IUserNote[]) => ({ type: '[Office] Add selected user notes', notes: notes })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
    this.gallery = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load gallery'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.galleryService.fetchGallery().pipe(
            map((gallery: string[]) => ({ type: '[Office] Add gallery', gallery: gallery })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
    this.usersStats = createEffect(() =>
      this.actions.pipe(
        ofType('[Office] Load users stats'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.userStats.officeUsersStats().pipe(
            map(stats => ({ type: '[Office] Add user stats', stats: stats })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
    this.gamesCategories = createEffect(() =>
      this.actions.pipe(
        ofType('[Searcher Session] Load games categories'),
        tap(() => this.officeStoreService.setLoading(true)),
        exhaustMap(() =>
          this.games.categories().pipe(
            map(categories => ({ type: '[Searcher Session] Add games categories', categories })),
            tap(() => this.officeStoreService.setLoading(false)),
            catchError(() => {
              this.officeStoreService.setLoading(false);
              return EMPTY;
            }),
          ),
        ),
      ),
    );
  }
}
